@font-face {
  font-family: RedHatText;
  src: url("./fonts/RedHatText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: RedHatText;
  src: url("./fonts/RedHatText-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: RedHatText;
  src: url("./fonts/RedHatText-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: RedHatText;
  src: url("./fonts/RedHatText-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: RedHatText;
  src: url("./fonts/RedHatText-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: RedHatText;
  src: url("./fonts/RedHatText-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

:root {
  --blue: #3eedd4;
  --purple: #7670ef;
  --dark-purple: #3f3c88;
  --primary: #3eedd4;
  --black: #1b1b1b;
  --black-80-opacity: rgba(0,0,0,0.80);
  --bg: #141414;
  --bg-container: #272727;
  --gray: #f1f1f1;
  --dark-gray: rgba(27, 27, 27, 0.80);
  --white: #fffdfd;
  --red: #f83a3a;

  font-family: RedHatText, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

#root {
  white-space: break-spaces;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html.no-scroll {
  overflow: hidden;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.gradient-overlay {
  background: linear-gradient(
    rgb(27, 27, 27) 0%,
    rgba(27, 27, 27, 0.4) 20%,
    rgba(27, 27, 27, 0.4) 80%,
    rgb(27, 27, 27) 100%
  );
}

.z-1 {
  position: relative;
  z-index: -1;
}

.button {
  border: 2px solid var(--primary);
  padding: 3px 17px;
  color: var(--black);
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  background-color: transparent;
  font-family: RedHatText, sans-serif;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.button.bg-white {
  background: var(--white);
}

.button:hover {
  background-color: var(--primary);
}

.button:hover.color-primary {
  color: var(--white);
}

.button:hover.blue {
  color: var(--white);
}

.button.red {
  border-color: var(--red);
}

.button:hover.red {
  border-color: var(--red);
  color: var(--white);
  background-color: var(--red);
}

.button.black {
  border-color: var(--black);
}

.button:hover.black {
  border-color: var(--black);
  color: var(--white);
  background-color: var(--black);
}

.button.cta {
  padding: 10px 20px;
  font-weight: 500;
  font-size: 16px;
}

.button a {
  text-decoration: none;
}

.button.small {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 13px;
}

.button.purple {
  border-color: var(--purple);
}

.button.purple:hover {
  background-color: var(--purple);
  color: var(--white);
}

.button.purple.disable-hover:hover {
  background-color: transparent;
}

.button.white {
  border-color: var(--white);
}

.button.white:hover {
  background-color: var(--white);
  color: var(--black);
}

.uppercase {
  text-transform: uppercase;
}

.button.login {
  width: 75px;
  height: 26px;
  padding: 3px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.disabled {
  opacity: 30%;
}

.opacity-80 {
  opacity: 0.8;
}

.disabled:hover {
  cursor: default;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.flex-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-start {
  justify-content: start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: end;
}

.items-start {
  align-items: start;
}

.items-baseline {
  align-items: baseline;
}

.m-4 {
  margin: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-10 {
  margin-right: 2.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-10 {
  margin-left: 2.25rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.p-8 {
  padding: 2rem;
}

.pointer {
  cursor: pointer;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.align-items-end {
  align-items: end;
}

.title {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 500;
}

.title.small {
  font-size: 20px;
}

h2.color-black,
h2.color-red,
h2.color-purple {
  color: var(--white);
}

h2.color-white,
h2.color-primary {
  color: var(--black);
}

h2.color-black:after {
  background-color: var(--black);
}

h2.color-white:after {
  background-color: var(--white);
}

h2.color-primary:after {
  background-color: var(--primary);
}

h2.color-red:after {
  background-color: var(--red);
}

h2.color-purple:after {
  background-color: var(--purple);
}

h2 {
  font-weight: 500;
  font-size: 32px;
  color: var(--white);
  padding: 4px 10px;
  position: relative;
  text-transform: uppercase;
  width: fit-content;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
}

h2:before {
  content: "";
  display: block;
  background: var(--purple);
  position: absolute;
  width: calc(100% + 20px);
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%) rotate(-2deg);
  z-index: -1;
  max-width: 100%;
}

h2.color-white h2 {
  color: var(--black);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.container.black {
  background: var(--bg-container);
}

select {
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 12px;
  background-color: white;
}

.no-decoration {
  text-decoration: none;
}

.color-purple {
  color: var(--purple);
}

.color-white {
  color: var(--white);
}

.color-primary {
  color: var(--primary);
}

.color-black {
  color: var(--black);
}

.color-black-80-opacity {
  color: var(--black-80-opacity)
}

.color-red {
  color: var(--red);
}



.required-asterix {
  margin-left: -8px;
  margin-top: 25px;
  color: var(--red);
  font-weight: 400;
  font-size: 18px;
  line-height: 125.8%;
  text-align: right;
  letter-spacing: 0.025em;
}

.overflow-hidden {
  overflow: hidden;
}

.no-break {
  white-space: initial;
}

.text {
  font-weight: 300;
  text-align: justify;
  margin-left: 10px;
  font-size: 15px;
}

@media screen and (max-width: 500px) {
  .button.cta {
    padding: 5px 10px;
    font-size: 12px;
  }

  h2 {
    font-size: 22px;
  }

  .text {
    font-size: 16px;
    line-height: 125.8%;
  }
}
