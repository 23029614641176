.podcasts-detail .wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.podcasts-detail .back {
  opacity: 80%;
  position: absolute;
  margin-top: -50px;
  margin-left: 100px;
}

.podcasts-detail .back a {
  text-decoration: none;
  color: var(--white);
  font-size: 22px;
  opacity: 80%;
}

.podcasts-detail .wrapper h2 {
  margin-top: -30px;
  margin-bottom: 40px;
}

.podcasts-detail .bg {
  margin-top: -120px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.podcasts-detail .wrapper .subtitle {
  font-weight: 500;
  font-size: 24px;
  color: var(--purple);
  margin-top: 77px;
  margin-bottom: 27px;
}

.podcasts-detail .wrapper .content-column {
  column-count: 2;
  column-gap: 75px;
}

.podcasts-detail .wrapper .content-bottom {
  display: flex;
  gap: 75px;
  margin-top: 67px;
}

.podcasts-detail .wrapper .content-bottom .image {
  width: calc(50% - (75px / 2));
  object-fit: cover;
}

.podcasts-detail .wrapper .content-bottom .text {
  width: calc(50% - (75px / 2));
}

.podcasts-detail .wrapper .content-bottom .bottom-subtitle {
  font-weight: 500;
  font-size: 24px;
  color: var(--purple);
  margin-bottom: 27px;
}

.podcasts-detail .bottom {
  height: 606px;
  max-height: 100vh;
  position: relative;
}

.podcasts-detail .bottom .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.podcasts-detail .bottom .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--white);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
}

.podcasts-detail .bottom .content .content-title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 30px;
}

.podcasts-detail .bottom .content .buttons {
  margin-top: 60px;
  justify-content: space-around;
}

@media screen and (max-width: 1360px) {
  .podcasts-detail .bg {
    margin-top: -1px;
  }

  .podcasts-detail .back {
    margin-top: -50px;
    width: 100%;
    display: flex;
    justify-content: end;
    margin-left: 0;
  }

  .podcasts-detail .back a {
      margin-right: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .podcasts-detail .wrapper {
    margin: 0 20px;
  }
}

@media screen and (max-width: 640px) {
  .podcasts-detail .wrapper h2 {
    margin-top: -10px;
  }
}

@media screen and (max-width: 500px) {
  .podcasts-detail .bottom .content {
    width: calc(100% - 40px);
    margin: 0 20px;
    left: 0;
    transform: translate(0, -50%);
  }
}
