.section3-wrapper {
  padding-top: 148px;
  background-color: var(--black);
  padding-bottom: 50px;
}

.section3-wrapper h2{
  right: 25%
}

.section3-wrapper .boxes {
  width: 100%;
  overflow: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 43px;
  -ms-overflow-style: none;
  filter: drop-shadow(0 0 100px #1B1B1B);
}

.section3-wrapper .boxes-fade {
  position: relative;
}

.section3-wrapper .boxes-fade:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #1B1B1B 0, rgba(27, 27, 27, 0) 124px),
  linear-gradient(to right, rgba(27, 27, 27, 0) calc(100% - 124px), #1B1B1B 100%);
}

.section3-wrapper .boxes .scroll::-webkit-scrollbar,
.section3-wrapper .boxes::-webkit-scrollbar {
  display: none;
}

.section3-wrapper .boxes .box {
  width: 400px;
  height: 250px;
  display: inline-block;
  cursor: grab;
  box-shadow: inset 0 0 20px #000000;
}

.section3-wrapper .boxes .box.text-square,
.section3-wrapper .boxes .box.img-square {
  width: 250px;
}
.section3-wrapper .boxes .box.img-rect,
.section3-wrapper .boxes .box.text-rect {
  width: 546px;
}

.section3-wrapper .boxes .box.text-square .content,
.section3-wrapper .boxes .box.img-square .content {
  width: 190px;
  height: 190px;
  border: 1px var(--purple) solid;
  margin: 30px;
  position: relative;
}

.section3-wrapper .boxes .box.img-rect .content,
.section3-wrapper .boxes .box.text-rect .content {
  width: 486px;
  height: 190px;
  border: 1px var(--primary) solid;
  margin: 30px;
  position: relative;
}

.section3-wrapper .boxes .box.text-square .content .figure,
.section3-wrapper .boxes .box.img-square .content .figure {
  background: var(--purple);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  transform: rotate(45deg);
}

.section3-wrapper .boxes .box.img-rect .content .figure,
.section3-wrapper .boxes .box.text-rect .content .figure {
  background: var(--primary);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  border-radius: 100%;
}

.section3-wrapper .boxes .box.img-rect .content .image,
.section3-wrapper .boxes .box.img-square .content .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section3-wrapper .boxes .box .box-title {
  font-weight: 500;
  font-size: 24px;
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 1;
}

.section3-wrapper .boxes .box .box-content {
  font-weight: 300;
  font-size: 15px;
  margin-left: 20px;
  margin-top: 49px;
  white-space: break-spaces;
  height: 82px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.section3-wrapper .boxes .box .button {
  position: absolute;
  right: 14px;
  bottom: 14px;
  z-index: 1;
}

.section3-wrapper .section3-footer {
  max-width: 1000px;
  padding: 43px 0px 134px;
}

.section3-wrapper .section3-footer .cta-button {
  margin-left: auto;
}


@media screen and (max-width: 1050px) {

  .section3-wrapper {
    padding-top: 75px;
  }

  .section3-wrapper .container .button {
    margin-right: 30px;
  }

  .section3-wrapper .section3-footer .cta-button {
    margin-left: auto;
    margin-right: auto;
  }

  .section3-wrapper .section3-footer {
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
  }

  .section3-wrapper .boxes .box {
    height: 200px;
  }

  .section3-wrapper .boxes .box.text-square,
  .section3-wrapper .boxes .box.img-square {
    width: 200px;
  }

  .section3-wrapper .boxes .box.img-rect,
  .section3-wrapper .boxes .box.text-rect {
    width: 500px;
  }

  .section3-wrapper .boxes .box.text-square .content,
  .section3-wrapper .boxes .box.img-square .content {
    width: 140px;
    height: 140px;
  }

  .section3-wrapper .boxes .box.img-rect .content,
  .section3-wrapper .boxes .box.text-rect .content {
    width: 440px;
    height: 140px;
  }

  .section3-wrapper .boxes-fade:after {
    background: linear-gradient(to right, #1B1B1B 0, rgba(27, 27, 27, 0) 20px),
    linear-gradient(to right, rgba(27, 27, 27, 0) calc(100% - 20px), #1B1B1B 100%);
  }

  .section3-wrapper .boxes .box .box-title {
    font-size: 18px;
  }

  .section3-wrapper .boxes .box .box-content {
    margin-top: 40px;
    height: 44px;
    margin-bottom: 0;
    font-size: 11px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .section3-wrapper .boxes .box .button {
    padding: 5px 10px;
    font-size: 12px;
  }

}
