.funding .funding-hero {
  height: 668px;
  max-height: 100vh;
  position: relative;
  margin-bottom: -1px;
  background-color: #1B1B1B;
}

.funding .funding-hero:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, #1B1B1B 0%, rgba(27, 27, 27, 0.4) 24.48%);
}

.funding .funding-hero .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.funding .funding-hero .content {
  position: absolute;
  color: var(--white);
  left: calc(max((100vw - 1000px), 40px) / 2);
  top: 50%;
  transform: translate(0, -50%);
}

.funding .funding-hero .content .description {
  margin-top: 30px;
  max-width: 700px;
  margin-right: 20px;
  max-height: calc(100vh - 400px);
  overflow: hidden;
  text-align: justify;
}

