.section8-wrapper {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 100px auto 200px auto;
  padding-left: 30px;
  padding-right: 30px;
}

.section8-wrapper .boxes {
  display: flex;
  flex-wrap: wrap;
}

.section8-wrapper .box {
  position: relative;
  height: calc((min(100vw, 1000px)) / 3);
}

.section8-wrapper .box .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section8-wrapper .box .bg {
  background: linear-gradient(
      180deg,
      rgba(118, 112, 239, 0.3) 0%,
      rgba(118, 112, 239, 0) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  height: 100%;
  width: 100%;
}

.section8-wrapper .box.w1-3 {
  width: calc((min(100vw - 60px, 1000px)) / 3);
}

.section8-wrapper .box.w2-3 {
  width: calc(2 * (min(100vw - 60px, 1000px)) / 3);
}

.section8-wrapper .box.w3-3 {
  width: calc(min(100vw - 60px, 1000px));
}

.section8-wrapper .box .box-title {
  font-weight: 500;
  font-size: 24px;
  color: var(--white);
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media screen and (max-width: 500px) {
  .section8-wrapper .box,
  .section8-wrapper .box.w1-3,
  .section8-wrapper .box.w2-3,
  .section8-wrapper .box.w3-3 {
    height: calc((min(100vw, 1000px) - 60px) / 2);
    width: calc((min(100vw, 1000px) - 60px) / 2);
  }

  .section8-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .section8-wrapper .flex-mobile {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .section8-wrapper .box .box-title {
    font-size: 15px;
  }
}
