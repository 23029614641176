.signup-wrapper {
  background-color: var(--white);
}

.signup-wrapper .left-content {
  width: 50%;
}

.signup-wrapper .left-content .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signup-wrapper .form-container {
  width: 962px;
  margin-left: -536px;
  background-color: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 55px 55px;
  margin-top: 250px;
  margin-bottom: 150px;
}

.signup-wrapper .form-container .content {
  width: 850px;
  margin: auto;
}

.signup-wrapper .form-container .content .title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 94.8%;
  text-transform: none;
  margin: 35px auto 10px;
}

.signup-wrapper .form-container .content .form-input-container {
  width: 100%;
  flex-wrap: wrap;
  column-gap: 50px;
}

.signup-wrapper .form-container .content .checkbox {
  margin-left: 448px;
  margin-top: 40px;
}

.signup-wrapper .form-container .content .text-checkbox {
  margin-bottom: 5px;
}

.signup-wrapper .form-container .purple-box {
  background: var(--purple);
  transform: rotate(-2deg);
  padding: 4px 10px;
  width: fit-content;
  margin-top: -45px;
  margin-left: -75px;
}

.signup-wrapper .form-container .purple-box-text {
  font-weight: 500;
  font-size: 32px;
  color: var(--white);
  transform: rotate(2deg);
  text-transform: uppercase;
}

@media screen and (max-width: 500px) {
  .signup-wrapper {
    flex-direction: column;
    background-color: var(--bg-container);
  }

  .signup-wrapper .form-container {
    width: calc(100% - 70px);
    margin: 0 10px 50px;
    padding: 25px;
  }

  .signup-wrapper .form-container .content {
    width: 100%;
  }

  .signup-wrapper .form-container .content .form-input-container {
    flex-direction: column;
  }

  .signup-wrapper .form-container .content .form-input-container .form__group {
    width: 100%;
  }

  .signup-wrapper .form-container .content .checkbox {
    margin-left: 0;
  }

  .signup-wrapper .form-container .content .title {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .signup-wrapper .form-container .content .title:first-child {
    margin-top: 0;
  }

  .signup-wrapper .form-container .content .title .dropdown-wrapper {
    margin-top: 10px;
  }

  .signup-wrapper .form-container .content .title .dropdown-wrapper .dropdown {
    text-align: center;
  }

  .signup-wrapper .form-container .purple-box {
    margin-left: 0;
    margin-bottom: 15px;
  }
}
