.img-rhombus-container {
  transform: translateY(25%) rotate(45deg);
  z-index: 10;
  background: var(--purple);
  width: calc(min(50vw, 420px) - 60px);
  height: calc(min(50vw, 420px) - 60px);
  margin-left: 62px;
}

.img-rhombus-container .img-rhombus {
  overflow: hidden;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  display: inline-block;
  margin: 30px;
}

.img-rhombus-container .img-rhombus .inner-wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160%;
  height: 160%;
}

.img-rhombus-container .img-rhombus .inner-wrapper img {
  transform: rotate(-45deg);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  object-fit: cover;
}

@media screen and (max-width: 800px) {
  .img-rhombus-container .img-rhombus {
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: 20px;
  }
}

@media screen and (max-width: 500px) {
  .img-rhombus-container {
    margin-left: 20px;
  }

  .img-rhombus-container .img-rhombus {
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    margin: 10px;
  }
}
