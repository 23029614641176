.scheduling .bg {
  background-color: var(--black);
}

.scheduling .bg.top {
  height: 100px;
  margin-bottom: -86px;
}

.scheduling .bg.bottom {
  height: 500px;
  margin-top: -500px;
}

.scheduling .bg.bottom .book-an-appointment {
  color: var(--purple);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 94.8%;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  margin-right: calc(50vw - 470px);
  margin-top: 50px;
  margin-left: auto;
}

.scheduling-top-wrapper .scheduling-top {
  max-width: 1000px;
  display: flex;
  margin: 0 auto 10px;
  padding: 0 25px;
}

.scheduling-top-wrapper .scheduling-top .scheduling-left-content {
  width: 50%;
}

.scheduling-top-wrapper
.scheduling-top
.scheduling-left-content
h2 {
  margin-top: 60px;
}

.scheduling-top-wrapper .scheduling-top .scheduling-right-content {
  width: 50%;
}

.scheduling-top-wrapper .scheduling-top .scheduling-left-content .text {
  margin-top: 23px;
  color: var(--black);
  margin-bottom: 31px;
}

.scheduling-top-wrapper .scheduling-top .scheduling-left-content {
  width: 50%;
  margin-right: 50px;
}

.scheduling-top-wrapper
.scheduling-top
.scheduling-right-content
.image-wrapper {
  height: 100%;
}

.scheduling-top-wrapper
.scheduling-top
.scheduling-right-content
.image-wrapper
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scheduling-top-wrapper .scheduling-top .scheduling-left-content .cta-button {
  margin-left: auto;
}

.scheduling-bottom-wrapper {
  background-color: var(--black);
  padding-bottom: 100px;
}

.scheduling-bottom-wrapper .scheduling-bottom {
  padding: 178px 25px 0;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.scheduling-bottom-wrapper .scheduling-bottom .scheduling-bottom-frame {
  box-shadow: inset 0 0 20px #000000;
  padding: 18px 45px 18px 18px;
  height: 299px;
  width: 100%;
}

.scheduling-bottom-wrapper
.scheduling-bottom
.scheduling-bottom-frame
.image-wrapper {
  width: 50%;
}

.scheduling-bottom-wrapper .scheduling-bottom h2 {
  margin-bottom: -15px;
  margin-left: -15px;
}

.scheduling-bottom-wrapper .scheduling-bottom .image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.scheduling-bottom-wrapper
.scheduling-bottom
.scheduling-bottom-frame
.scheduling-bottom-title {
  font-weight: 500;
  font-size: 32px;
  color: var(--primary);
  margin-left: 31px;
  margin-top: 20px;
}

.scheduling-bottom-wrapper
.scheduling-bottom
.scheduling-bottom-frame
.scheduling-bottom-content {
  font-weight: 300;
  font-size: 16px;
  color: var(--white);
  margin-left: 31px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.calendly-widget-placeholder {
  text-align: center;
  font-size: 25px;
  height: 100px;
}

@media screen and (max-width: 1000px) {
  .scheduling .bg.bottom .book-an-appointment {
    margin-right: calc(50vw - 390px);
  }
}

@media screen and (max-width: 850px) {
  .scheduling .calendly {
    margin: 0 10px;
  }

  .scheduling-top-wrapper {
    padding: 0 30px;
  }

  .scheduling-top-wrapper .scheduling-top {
    flex-direction: column;
    padding: 0;
  }

  .scheduling-top-wrapper .scheduling-top .scheduling-left-content {
    width: 100%;
    margin-bottom: 30px;
  }

  .scheduling-top-wrapper .scheduling-top .scheduling-right-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 80px;
  }

  .scheduling-top-wrapper .scheduling-top .scheduling-left-content .cta-button {
    margin-left: auto;
  }

  .scheduling-bottom-wrapper .scheduling-bottom {
    position: relative;
    padding: 78px 30px 30px 30px;
  }

  .scheduling-bottom-wrapper .scheduling-bottom .scheduling-bottom-frame {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    height: auto;
    margin-right: 0;
    padding: 0;
  }

  .scheduling-bottom-wrapper
  .scheduling-bottom
  .scheduling-bottom-frame
  .scheduling-bottom-title {
    margin-left: 0;
  }

  .scheduling-bottom-wrapper
  .scheduling-bottom
  .scheduling-bottom-frame
  .scheduling-bottom-content {
    margin-left: 0;
  }

  .scheduling-bottom-wrapper
  .scheduling-bottom
  .scheduling-bottom-frame
  .image-wrapper {
    width: 100%;
  }

  .scheduling .bg.bottom .book-an-appointment {
    font-size: 22px;
    writing-mode: horizontal-tb;
    margin-right: 0;
    margin-top: -260px;
    margin-left: 14px;
  }
}

@media screen and (max-width: 650px) {
  .scheduling .bg.bottom .book-an-appointment {
    margin-top: -310px;
  }
}

@media screen and (max-width: 500px) {
  .scheduling-top-wrapper .scheduling-top .scheduling-left-content .text {
    font-size: 15px;
  }
}