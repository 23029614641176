.section6-wrapper {
  background-color: var(--black);
  padding-left: 30px;
  padding-right: 30px;
}

.section6-wrapper .section6 {
  padding-top: 178px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  min-height: calc(584px - 94px - 76px);
}

.section6-wrapper .section6 .left-content {
  width: 50%;
}

.section6-wrapper .section6 .left-content .text {
  margin-top: 23px;
  margin-bottom: 31px;
}

.section6-wrapper .section6 .left-content {
  width: 50%;
  margin-right: 50px;
}

.section6-wrapper .section6 .right-content {
  width: 50%;
}

.section6-wrapper .section6 .right-content .image-wrapper .image {
  width: 100%;
  object-fit: contain;
}

.section6-wrapper .section6 .left-content .cta-button {
  margin-left: auto;
}

@media screen and (max-width: 850px) {
  .section6-wrapper {
    padding: 30px;
  }

  .section6-wrapper .section6 {
    flex-direction: column;
    padding-top: 30px;
  }

  .section6-wrapper .section6 .left-content {
    width: 100%;
    margin-bottom: 30px;
  }

  .section6-wrapper .section6 .right-content {
    width: 100%;
    margin-left: 0;
  }

  .section6-wrapper .section6 .left-content .cta-button {
    margin-left: auto;
  }
}
