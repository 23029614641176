.signup-options {
  position: relative;
}

.signup-options .left-content {
  width: 50%;
  height: 732px;
}

.signup-options .left-content .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.signup-options .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: calc(962px);
}

.signup-options .content h2 {
  margin-bottom: -20px;
  margin-left: -20px;
}

.signup-options .content .inner-content {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  padding: 88px 130px;
}

.signup-options .content .inner-content .signup-title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 30px;
}

.signup-options .content .inner-content .boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.signup-options .content .inner-content .boxes .box {
  padding: 10px 20px;
  background-color: var(--purple);
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}

.signup-options .content .inner-content .boxes .box:hover {
  background-color: var(--dark-purple);
}

@media screen and (max-width: 500px) {
  .signup-options {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .signup-options .left-content {
    width: 100%;
    height: auto;
    background-color: var(--black);
  }

  .signup-options .content {
    display: flex;
    flex-direction: column;
    position: unset;
    transform: none;
    background-color: var(--black);
  }

  .signup-options .content h2 {
    margin-left: 26px;
    margin-top: -45px;
  }

  .signup-options .content .inner-content {
    padding: 26px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .signup-options .content .inner-content .boxes {
    gap: 12px;
  }
}
