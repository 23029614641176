.pageloader img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.pageloader * {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.pageloader {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  background-color: #fff;
  display: block;
  opacity: 1;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.div-block-15 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
}

.gradouter {
  position: relative;
  max-width: 90vw;
}
.gradwrap {
  position: relative;
  display: flex;
  width: 1vw;
  height: 1vh;
  min-height: 150px;
  min-width: 349px;
  padding: 50px;
  justify-content: center;
  align-items: center;
}

.graddiv {
  position: relative;
  padding: 9px;
  background-color: #1b1b1b;
}

.graddiv.gradient-border {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 55px;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
}

.image-2 {
  position: absolute;
  width: 300px;
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
    rotateZ(0) skew(0, 0);
}

.gradwrap {
  position: relative;
  padding: 9px;
  overflow: hidden;
}
.graddiv {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
.gradwrap::before {
  content: "";
  width: 250%;
  height: 300%;
  position: absolute;
  top: auto;
  left: auto;
  background: conic-gradient(#3eedd4, #7670ef, #7670ef, #3eedd4);
  animation: rotate-border 5s linear infinite;
}
.gradouter::before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3eedd4;
  position: absolute;
  top: -12px;
  left: calc(50% - 12px);
  z-index: 5;
  animation: rotateTopDiv 5s linear infinite;
}
@keyframes rotateTopDiv {
  0% {
    top: -12px;
    left: calc(50% - 12px);
  }
  12.5% {
    top: -12px;
    left: calc(100% - 18px);
  }
  25% {
    top: calc(50% - 12px);
    left: calc(100% - 18px);
  }
  37.5% {
    top: calc(100% - 18px);
    left: calc(100% - 18px);
  }
  50% {
    top: calc(100% - 18px);
    left: calc(50% - 18px);
  }
  62.5% {
    top: calc(100% - 18px);
    left: -10px;
  }
  75% {
    top: calc(50% - 18px);
    left: -10px;
  }
  87.5% {
    top: -12px;
    left: -10px;
  }
  100% {
    top: -12px;
    left: calc(50% - 12px);
  }
}
.gradouter::after {
  content: "";
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  background-color: #7670ef;
  position: absolute;
  top: calc(100% - 15px);
  left: calc(50% - 14px);
  z-index: 5;
  animation: rotateBottomDiv 5s linear infinite;
}
@keyframes rotateBottomDiv {
  0% {
    top: calc(100% - 17px);
    left: calc(50% - 17px);
  }
  12.5% {
    top: calc(100% - 17px);
    left: -10px;
  }
  25% {
    top: calc(50% - 17px);
    left: -10px;
  }
  37.5% {
    top: -10px;
    left: -10px;
  }
  50% {
    top: -10px;
    left: calc(50% - 17px);
  }
  62.5% {
    top: -10px;
    left: calc(100% - 17px);
  }
  75% {
    top: calc(50% - 17px);
    left: calc(100% - 17px);
  }
  87.5% {
    top: calc(100% - 17px);
    left: calc(100% - 17px);
  }
  100% {
    top: calc(100% - 17px);
    left: calc(50% - 17px);
  }
}
@keyframes rotate-border {
  to {
    transform: rotate(360deg);
  }
}
