.checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  padding-top: 2px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 15px;
  width: 15px;
  background: transparent;
  border: 1px solid var(--black);
  border-radius: 2px;
}

.checkbox-container.white-checkbox {
  color: white;
}

.checkbox-container.white-checkbox .checkmark {
  border-color: var(--white);
}

.checkbox-container:hover input ~ .checkmark,
.checkmark.disabled {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: var(--purple);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
