.community .corporate-hero {
  margin-top: -120px;
}

.corporates.about.academy.community .bottom .content {
  left: calc((100vw - 1000px)/2);
  transform: translate(0, -50%);
}

@media screen and (max-width: 1360px) {
  .community .corporate-hero {
    margin-top: 0;
  }

  .corporates.about.academy.community .bottom .content {
    height: fit-content;
    left: 0;
    transform: translate(0, -50%);
    padding: 0;
  }
}



