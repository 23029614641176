.section5-wrapper {
  background-color: var(--black);
  padding-right: 30px;
  padding-left: 30px;
}

.section5-wrapper .section5 {
  padding-top: 178px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 370px;
}

.section5-wrapper .section5 .arrows-container {
  align-items: center;
  height: inherit;
}

.section5-wrapper .section5 .margin {
  margin-left: 45px;
}

.section5-wrapper .section5 .section5-frame {
  box-shadow: inset 0 0 20px #000000;
  padding: 18px 45px 18px 18px;
  margin-left: 70px;
  margin-right: 70px;
  height: 299px;
  /*min-width: min(
    1000px,
    calc(100vw - max(calc((100vw - 1000px)), 0px) - 140px)
  );*/
  position: absolute;
}

.section5-wrapper .section5 .section5-frame.hide {
  opacity: 0;
  position: initial;
}

.section5-wrapper .section5 h2 {
  margin-bottom: -15px;
}

.section5-wrapper .section5 .image {
  height: 100%;
  width: 338px;
  object-fit: cover;
}

.section5-wrapper .section5 .section5-frame .section5-title {
  font-weight: 500;
  font-size: 32px;
  margin-left: 31px;
  margin-top: 20px;
}

.section5-wrapper .section5 .section5-frame .section5-content {
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  font-weight: 300;
  font-size: 16px;
  margin-left: 31px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section5-wrapper .section5 .section5-frame .section5-button {
  max-width: 346px;
}

@media screen and (max-width: 850px) {
  .section5-wrapper .section5 {
    position: relative;
    padding: 78px 0px 30px 0px;
    height: 676px;
  }

  .section5-wrapper .section5 .margin {
    margin-left: 0;
  }

  .section5-wrapper .section5 h2 {
    max-width: 60vw;
  }

  .section5-wrapper .section5 .arrow-left {
    top: 78px;
    right: 70px;
    position: absolute;
  }

  .section5-wrapper .section5 .arrow-right {
    position: absolute;
    top: 78px;
    right: 30px;
  }

  .section5-wrapper .section5 .section5-frame {
    box-shadow: none;
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
    height: auto;
    margin-right: 0;
    padding: 0;
  }

  .section5-wrapper .section5 .section5-frame .section5-title {
    margin-left: 0;
  }

  .section5-wrapper .section5 .section5-frame .section5-content {
    margin-left: 0;
  }

  .section5-wrapper .section5 .image {
    margin-top: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .section5-wrapper .section5 h2 {
    max-width: 45vw;
  }
}
