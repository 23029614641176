.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  max-width: 100%;
  width: 400px;
}
.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--black);
  outline: 0;
  font-size: 1.3rem;
  color: var(--black);
  padding: 7px 0;
  transition: border-color 0.2s;
}

.form__field.transparent {
  background: transparent;
}

.form__field.color-white {
  color: var(--white);
  border-color: var(--white);
}

.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 18px;
  cursor: text;
  top: 25px;
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--dark-gray);
}
.form__label.color-white {
  color: var(--white);
}

.form__field:focus {
  padding-bottom: 6px;
  border-width: 3px;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--black);
  font-weight: 700;
}

.form__field:focus ~ .form__label.color-white {
  color: var(--white);
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.input-img {
  width: 100px;
}

.input-vid {
  width: 100%;
}

.form__group.nr {
  width: 70px;
}

.form__group.city {
  width: 140px;
}

.form__group.zip {
  width: 90px;
}
