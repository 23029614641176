.privacy-policy {
  width: 800px;
  max-width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  word-break: break-word;
}

.bold {
  font-weight: 500;
  text-decoration: underline;
}
