.podcasts .title-wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  margin-bottom: -30px;
}

.podcasts .bg-wrapper {
  background: var(--bg-container);
  padding-top: 80px;
  padding-bottom: 200px;
}

.podcasts .wrapper {
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.podcasts .wrapper .podcast {
  height: 267px;
  background: var(--bg);
  box-shadow: inset 0 0 20px #000000;
  padding: 30px;
  text-decoration: none;
}

.podcasts .wrapper .podcast .content-wrapper {
  border: 1px solid var(--primary);
  position: relative;
  display: flex;
  height: 100%;
}

.podcasts .wrapper .podcast.purple .content-wrapper {
  border: 1px solid var(--purple);
}

.podcasts .wrapper .podcast .content-wrapper .figure {
  background: var(--primary);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  border-radius: 100%;
  z-index: 10;
}

.podcasts .wrapper .podcast.purple .content-wrapper .figure {
  background: var(--purple);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  transform: rotate(45deg);
  z-index: 10;
}

.podcasts .wrapper .podcast .content-wrapper .image-wrapper {
  width: 267px;
  height: 100%;
  position: relative;
}

.podcasts .wrapper .podcast .content-wrapper .image-wrapper .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.podcasts .wrapper .podcast .content-wrapper .image-wrapper .original {
  position: absolute;
  left: 15px;
  top: 10px;
}

.podcasts .wrapper .podcast .content-wrapper .image-wrapper .type {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.podcasts .wrapper .podcast .content-wrapper .content {
  color: var(--white);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 267px - 40px);
}

.podcasts .wrapper .podcast .content-wrapper .content .topic {
  margin-left: auto;
  font-weight: 400;
  font-size: 17px;
}

.podcasts .wrapper .podcast .content-wrapper .content .podcast-title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 14px;
}

.podcasts .wrapper .podcast .content-wrapper .content .description {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 10px;
  overflow: hidden;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .podcasts .title-wrapper {
    margin-left: 20px;
    padding-top: 70px;
  }

  .podcasts .wrapper .podcast {
    height: 135px;
  }

  .podcasts .wrapper .podcast .content-wrapper .image-wrapper {
    width: 135px;
  }

  .podcasts .wrapper .podcast .content-wrapper .content .topic {
    display: none;
  }

  .podcasts .wrapper .podcast .content-wrapper .content .button {
    display: none;
  }

  .podcasts .wrapper .podcast .content-wrapper .content {
    width: calc(100% - 135px - 40px);
  }

  .podcasts .wrapper .podcast .content-wrapper .content .podcast-title {
    margin-bottom: 5px;
    font-size: 16px;
  }

  .podcasts .wrapper .podcast .content-wrapper .content .description {
    font-size: 10px;
  }

  .podcasts .wrapper .podcast .content-wrapper .image-wrapper .type {
    width: 18px;
    height: 18px;
  }

}
