.start-smart {
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}

.start-smart .section-1 {
  display: flex;
  gap: 50px;
  margin-top: 50px;
}

.start-smart .section-1 .left {
  margin-top: 40px;
  width: 50%;
}

.start-smart .section-1 .left h2 {
  margin-bottom: 30px;
}

.start-smart .section-1 .right {
  width: 50%;
}

.start-smart .section-1 .right .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.start-smart .title-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.start-smart .box-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 34px;
  margin-bottom: 150px;
  position: relative;
}

.start-smart .box-wrapper .box {
  background: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: min(calc(50% - 57px), 543px);
  height: 250px;
}

.start-smart .box-wrapper .box.blurred {
  filter: blur(8px);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.start-smart .login-overlay {
  position: absolute;
  left: 50%;
  top: 400px;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 54px rgba(0, 0, 0, 0.55));
  z-index: 1;
  align-items: center;
}

.start-smart .login-overlay .button {
  width: 116px;
  display: flex;
  justify-content: center;
  font-size: 22px;
  margin-bottom: 5px;
}
.start-smart .login-overlay  .sign-up-subtitle {
  font-size: 18px;
  font-weight: 400;
  color: var(--black);
}

.start-smart .login-overlay  .or-subtitle {
  font-size: 18px;
  font-weight: 400;
  color: rgba(72, 72, 72, 0.8);
}

.start-smart .box-wrapper .box.alternative {
  background: var(--purple);
  color: white;
}

.start-smart .box-wrapper .box.alternative .box-title {
  text-align: center;
  font-weight: 500;
  font-size: 28px;
}

.start-smart .box-wrapper .box.alternative .text {
  text-align: center;
  margin-top: auto;
  overflow: hidden;
}

.start-smart .box-wrapper .box .top-wrapper {
  display: flex;
}

.start-smart .box-wrapper .box .top-wrapper .box-title {
  color: var(--purple);
  font-weight: 500;
  font-size: 24px;
}

.start-smart .box-wrapper .box .top-wrapper .box-sector {
  font-weight: 400;
  font-size: 16px;
}

.start-smart .box-wrapper .box .top-wrapper .image-wrapper {
  margin-left: auto;
  width: 115px;
  max-height: 82px;
}

.start-smart .box-wrapper .box .top-wrapper .image-wrapper .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.start-smart .box-wrapper .box .text {
  font-weight: 300;
  font-size: 15px;
  margin-top: 12px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.start-smart .box-wrapper .box .price {
  font-weight: 600;
  font-size: 19px;
  color: var(--primary);
  max-width: 50%;
}

.start-smart .box-wrapper .box .price.blurred {
  filter: blur(8px);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media screen and (max-width: 1050px) {
  .start-smart .section-1, .start-smart .box-wrapper {
    padding: 0 25px;
  }
}

@media screen and (max-width: 850px) {
  .start-smart .box-wrapper .box {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .start-smart .section-1, .start-smart .box-wrapper {
    padding: 0;
  }

  .start-smart {
    margin-right: 20px;
    margin-left: 20px;
    width: calc(100% - 40px);
  }

  .start-smart .section-1 {
    flex-direction: column;
    width: 100%;
  }

  .start-smart .section-1 .left,
  .start-smart .section-1 .right {
    width: 100%;
  }

  .start-smart .section-1 .left h2 {
    font-size: 22px;
  }

  .start-smart .section-1 .left .content {
    font-size: 15px;
    text-align: justify;
  }

  .start-smart .title-wrapper {
    width: 100%;
  }

  .start-smart .title-wrapper h2 {
    font-size: 22px;
  }

  .start-smart .box-wrapper .box .top-wrapper .box-title {
    font-size: 18px;
  }

  .start-smart .box-wrapper .box .top-wrapper .box-sector {
    font-size: 14px;
  }

  .start-smart .box-wrapper .box .text {
    font-size: 12px;
  }

}
