.dropdown-wrapper {
  margin-left: 10px;
  margin-top: -2px;
  background-image: url("../assets/arrowDown.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  width: fit-content;
}

.dropdown {
  font-family: inherit;
  border: 0;
  outline: 0;
  color: var(--purple);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  margin-top: -4px;
  background: transparent;
  padding-right: 20px;
}
