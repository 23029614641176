.contact .bg-header {
  max-width: 100%;
  width: 100%;
}

.contact .content-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.contact .content-wrapper h2 {
  margin-top: -30px;
  margin-left: -15px;
}

.contact .content-wrapper .main-title h2 {
  font-size: 35px;
}

.contact .content-wrapper .content {
  display: flex;
  gap: 50px;
  margin-top: 35px;
  margin-bottom: 100px;
}

.contact .content-wrapper .content .text,
.contact .content-wrapper .content .image {
  width: 50%;
  object-fit: cover;
}

.contact .content-wrapper .contact-form {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  background: var(--white);
}

.contact .content-wrapper .contact-form .input-wrapper {
  display: flex;
  gap: 66px;
}

.contact .content-wrapper .contact-form .form__group {
  width: 100%;
}

.contact .content-wrapper .extra {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 100px;
  margin-top: 20px;
  color: var(--white);
}

.contact .content-wrapper .extra a {
  color: var(--primary);
}

.contact .bg {
  background-color: var(--bg-container);
  height: 650px;
  margin-top: -650px;
  position: sticky;
  z-index: -1;
}

.contact .content-wrapper .contact-form a {
  color: var(--black);
}

@media screen and (max-width: 1050px) {
  .contact .content-wrapper .content .text {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1020px) {
  .contact .content-wrapper h2 {
    margin-left: 20px;
  }

  .contact .content-wrapper .extra {
    margin-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .contact .content-wrapper .content .text,
  .contact .content-wrapper .content .image {
    width: 100%;
  }

  .contact .content-wrapper .content .text {
    padding: 0;
  }

  .contact .content-wrapper .content {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 60px;
  }

  .contact .content-wrapper .contact-form {
    padding: 50px 25px;
  }

  .contact .content-wrapper .contact-form .input-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .contact .content-wrapper h2 {
    margin-left: 20px;
  }
}
