.section2-wrapper {
  margin: 90px auto 0 auto;
}

.section2-wrapper .section2-title {
  margin: 0 30px;
}

.section2-wrapper .frames {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.section2-wrapper .frames .frame {
  width: calc((min(100vw - 120px, 1400px)) / 5);
  height: calc((min(100vw - 120px, 1400px)) / 5);
  overflow: hidden;
}

.section2-wrapper .frames .frame .image-wrapper {
  width: 100%;
  height: 100%;
}

.section2-wrapper .frames .frame .image-wrapper .image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.section2-wrapper .frames .frame {
  position: relative;
}

.section2-wrapper .frames .frame .frame-content {
  background-color: rgba(118, 112, 239, 0);
  position: absolute;
  height: calc(80% - 36px);
  color: var(--white);
  padding: 18px;
  display: flex;
  flex-direction: column;
  bottom: calc(-28%);
  transition: background-color 1s, bottom 1s;
  justify-content: space-between;
  width: calc(100% - 36px);
}

.section2-wrapper .frames .frame .frame-button {
  transition: opacity 0.5s;
  opacity: 0;
  display: none;
}

.section2-wrapper .frames .frame .frame-button:hover a {
  color: var(--purple);
}

.section2-wrapper .frames .frame .overlay {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(118, 112, 239, 0.3) 0%,
    rgba(118, 112, 239, 0) 100%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 100%;
  width: 100%;
}

.section2-wrapper .frames .frame .overlay-title {
  position: absolute;
  bottom: 18px;
  font-weight: 500;
  font-size: 24px;
  left: 18px;
}

.section2-wrapper .frames .frame:hover .overlay {
  display: none;
}

.section2-wrapper .frames .frame:hover .frame-content {
  background-color: var(--purple);
  bottom: 0;
}

.section2-wrapper .frames .frame:hover .frame-content .frame-button {
  opacity: 1;
  display: flex;
}

.section2-wrapper .frames .frame .frame-content .frame-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 94.8%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: unset;
  overflow: hidden;
  margin-bottom: 5px;
}

.section2-wrapper .frames .frame:hover .frame-content .frame-description {
  font-weight: 300;
  overflow: hidden;
  font-size: 15px;
  display: flex;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.section2-wrapper .frames .frame .frame-content .frame-description {
  display: none;
}

.section2-wrapper .black-bg {
  background-color: var(--black);
  margin-top: calc(-1 * ((min(100vw, 1200px) - 50px) / 5) / 2);
  padding-top: calc((((min(100vw, 1200px) - 50px) / 5) / 2) + 43px);
  padding-bottom: 43px;
}

@media screen and (max-width: 1130px) {
  .section2-wrapper .frames .frame .frame-title {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 900px) {
  .section2-wrapper .frames .frame .frame-title {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 750px) {
  .section2-wrapper {
    margin-top: 70px;
  }

  .section2-wrapper .frames .frame {
    width: calc((min(100vw, 1200px) - 50px) / 2);
    height: calc((min(100vw, 1200px) - 50px) / 2);
  }

  .section2-wrapper .frames .frame:hover .overlay {
    display: flex;
  }

  .section2-wrapper .frames .frame .overlay-title,
  .section2-wrapper .frames .frame .frame-title {
    font-size: 15px !important;
  }

  .section2-wrapper .frames .frame:hover .frame-content {
    display: none;
  }

  .section2-wrapper .button {
    margin-left: 30px;
    margin-right: 30px;
  }
}
