.confirm {
  text-align: center;
}

.confirm .confirm-hero {
  width: 100%;
  margin-top: -120px;
  position: relative;
}

.confirm .confirm-hero img {
  width: 100%;
}

.confirm .confirm-hero .content {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--white);
  margin-left: auto;
  margin-right: auto;
  top: 44%;
}

.confirm .confirm-hero .content h2 {
  margin-bottom: 30px;
}

.confirm .confirm-hero .content .text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 1360px) {
  .confirm .confirm-hero {
    margin-top: 0;
  }
}
