.section1-wrapper {
  margin-top: 188px;
  padding-left: 30px;
  padding-right: 30px;
}

.section1-wrapper .section1 {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  min-height: calc(327px);
}

.section1-wrapper .section1 .left-content {
  width: 50%;
}

.section1-wrapper .section1 .right-content .text {
  margin-top: 30px;
  margin-bottom: 31px;
}

.section1-wrapper .section1 .right-content {
  width: 50%;
  margin-left: 50px;
}

.section1-wrapper .section1 .left-content .image {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.section1-wrapper .section1 .right-content .cta-button {
  margin-left: auto;
}

@media screen and (max-width: 850px) {
  .section1-wrapper {
    margin-top: 20px;
    padding: 30px;
  }

  .section1-wrapper .section1 {
    flex-direction: column-reverse;
  }

  .section1-wrapper .section1 .left-content {
    width: 100%;
  }

  .section1-wrapper .section1 .right-content {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }

  .section1-wrapper .section1 .right-content .cta-button {
    margin-left: auto;
  }
}
