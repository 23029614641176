.sm-deal {
  font-style: normal;
  font-size: 16px;
  text-align: justify;
}

.sm-deal .mb {
  margin-bottom: 140px;
}

.sm-deal .content-wrapper {
  display: flex;
  gap: 80px;
  margin-bottom: 30px;
}

.sm-deal .content-wrapper .left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.sm-deal .content-wrapper .left .text {
  margin-top: 13px;
  margin-bottom: 50px;
}

.sm-deal .content-wrapper .left .logo {
  width: 140px;
  height: 50px;
  object-fit: contain;
  margin-left: auto;
}

.sm-deal .content-wrapper .right {
  width: 50%;
}

.sm-deal .content-wrapper .right .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: -70px;
}

.sm-deal .description2 {
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .sm-deal .content-wrapper {
    flex-direction: column;
    margin: 0 20px;
  }

  .sm-deal .content-wrapper .left,
  .sm-deal .content-wrapper .right {
    width: 100%;
  }

  .sm-deal .description2 {
    margin: 40px 20px 0;
  }

  .sm-deal .content-wrapper .right .image {
    margin-top: 0;
  }
}
