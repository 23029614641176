.corporates {
  margin-top: -1px;
}

.corporates .grid .grid-wrapper {
  background: var(--bg-container);
  padding-bottom: 0px;
}

.corporates .corporate-hero {
  height: 668px;
  max-height: 100vh;
  position: relative;
  margin-bottom: -1px;
  background-color: #1B1B1B;
}

.corporates .corporate-hero:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, #1B1B1B 0%, rgba(27, 27, 27, 0.4) 24.48%);
}

.corporates .corporate-hero .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.corporates .corporate-hero .content {
  padding: 0 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--white);
  max-width: 1000px;
  left: calc(max((100vw - 1000px), 0px) / 2);
  top: 40%;
  transform: translate(0, -50%);
}

.corporates .corporate-hero .content h2 {
  margin-bottom: 30px;
}

.corporates .corporate-hero .content .text {
  max-width: 540px;
}

.corporates .bottom {
  height: 606px;
  max-height: 100vh;
  position: relative;
}

.corporates .bottom .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.corporates .bottom .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  color: var(--white);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-width: 650px;
  margin: 0 20px;
}

.corporates .bottom .content.form-type{
  max-width: 950px;
}

.corporates .bottom .content .content-title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 30px;
  text-align: justify;
}

.corporates .bottom .content .buttons {
  margin-top: 60px;
  justify-content: space-around;
}

.corporates .bottom .content .buttons .fixed {
  margin-left: auto;
  margin-right: 105px;
}

.corporates .section-2-wrapper {
  background-color: var(--bg-container);
  padding-top: 100px;
  padding-bottom: 160px;
}

.corporates .section-2-wrapper .section-2 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  position: relative;
}

.corporates .corporate-hero .button-wrapper,
.corporates .section-2-wrapper .section-2 .button-wrapper {
  margin-top: 30px;
}

.corporates .section-2-wrapper .section-2 .circle {
  background-color: var(--primary);
  width: calc(50vw - (max((100vw - 1000px) / 2, 0px)));
  height: calc(50vw - (max((100vw - 1000px) / 2, 0px)));
  max-width: 474px;
  max-height: 474px;
  border-radius: 100%;
}

.corporates .section-2-wrapper .section-2 .line {
  position: absolute;
  background-color: var(--primary);
  height: 23px;
  width: calc(max((100vw - 1000px) / 2, 0px) + 1px);
  left: calc(-1 * max((100vw - 1000px) / 2, 0px));
  top: calc(50% - (23px / 2));
}

.corporates .section-2-wrapper .section-2 .line::before {
  content: "";
  position: absolute;
  top: 23px;
  right: -3px;
  width: 37px;
  height: 58px;
  border-radius: 0 0 25px 0;
}

.corporates .section-2-wrapper .section-2 .line::after {
  content: "";
  position: absolute;
  top: -58px;
  right: -3px;
  width: 37px;
  height: 58px;
  border-radius: 0 0 25px 0;
}

.corporates .section-2-wrapper .section-2 .line::after {
  box-shadow: 0 22px 0 -1px var(--primary);
}

.corporates .section-2-wrapper .section-2 .line::before {
  box-shadow: 0 22px 0 -1px var(--primary);
  transform: scaleX(-1);
  rotate: 180deg;
}

.corporates .section-2-wrapper .section-2 .content {
  width: 50%;
}

.corporates .section-2-wrapper .section-2 .content h2 {
  margin-bottom: 50px;
}

.corporates .section-2-wrapper .section-2 .content .content-title {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: var(--purple);
  margin-bottom: 18px;
}

.corporates .section-2-wrapper .section-2 .content .button {
  margin-top: 35px;
}

.corporates .section-3-wrapper {
  background-color: var(--bg-container);
  padding-bottom: 80px;
  padding-left: 15px;
  padding-right: 15px;
}

.corporates .section-3-wrapper .section-3 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.corporates .section-3-wrapper .section-3 h2 {
  margin-left: -15px;
}

.corporates .content form .input-wrapper {
  display: flex;
  gap: 20px;
}

.corporates .content form .form-title {
  font-weight: 500;
  font-size: 32px;
  color: var(--primary);
}

.corporates .bottom .content .left,
.corporates .bottom .content .right {
  width: 50%;
}

.corporates .bottom .content .left {
  margin-right: 50px;
}

.corporates .bottom .content .right {
  margin-left: 50px;
  margin-right: 40px;
}

@media screen and (max-width: 1050px) {
  .corporates .section-2-wrapper {
    padding-top: 80px;
  }

  .corporates .section-2-wrapper .section-2 {
    padding: 0 20px;
  }

  .corporates .bottom .content .buttons .fixed {
    margin-left: unset;
    margin-right: unset;
  }
}

@media screen and (max-width: 1125px) {
  .corporates .section-2-wrapper .section-2 {
    flex-direction: column;
    align-items: center;
  }

  .corporates .section-2-wrapper .section-2 .circle {
    margin-bottom: 60px;
  }

  .corporates .section-2-wrapper .section-2 .line {
    position: absolute;
    background-color: var(--primary);
    height: 23px;
    width: calc(29vw + 3px);
    left: -29vw;
    top: calc(50% - (81px / 2));
  }

  .corporates .section-2-wrapper .section-2 .content {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {

  .corporates .corporate-hero .content,
  .corporates .bottom .content {
    height: fit-content;
    left: 0;
    transform: translate(0, -50%);
    padding: 0;
  }

  .corporates .corporate-hero .content {
    margin: 0 20px;
  }

  .corporates .bottom .content .content-container {
    flex-direction: column;
  }

  .corporates .bottom .content .left,
  .corporates .bottom .content .right {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-top: 30px;
  }

  .corporates .bottom .content .left {
    margin-bottom: 20px;
  }

  .corporates .bottom {
    max-height: none;
    height: 950px;
  }

  .corporates .section-2-wrapper .section-2 .content .content-title,
  .corporates .bottom .content .content-title {
    font-size: 22px;
  }
}
