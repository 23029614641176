.academy .mid-section {
  padding-top: 130px;
  padding-bottom: 90px;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  min-height: calc(584px - 94px - 76px);
}

.academy .mid-section .left-content {
  width: 50%;
  justify-content: center;
}

.academy .mid-section .left-content .text {
  margin-top: 23px;
  margin-bottom: 31px;
}

.academy .mid-section .left-content {
  width: 50%;
  margin-right: 50px;
}

.academy .mid-section .right-content {
  width: 50%;
}
.academy .mid-section .right-content .image-wrapper {
  height: 100%;
}

.academy .mid-section .right-content .image-wrapper .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.academy .mid-section .left-content .cta-button {
  margin-left: auto;
}

@media screen and (max-width: 850px) {
  .academy .mid-section {
    flex-direction: column;
    padding-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .academy .mid-section .left-content {
    width: 100%;
    margin-bottom: 30px;
  }

  .academy .mid-section .right-content {
    width: 100%;
    margin-left: 0;
  }

  .academy .mid-section .left-content .cta-button {
    margin-left: auto;
  }
}

@media screen and (max-width: 700px) {
  .academy .bottom .content {
    width: 93%;
  }
}