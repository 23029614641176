.login-container-wrapper {
  background-color: var(--white);
  height: 732px;
}

.login-container-wrapper .left-content {
  width: 50%;
  position: relative;
}

.login-container-wrapper .left-content .image {
  width: 100%;
  height: 732px;
  object-fit: cover;
}

.login-container-wrapper .left-content .text {
  font-weight: 700;
  font-size: 48px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 80px;
  text-transform: uppercase;
  max-width: 340px;
}

.login-container-wrapper .right-content {
  width: 50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -68px;
  position: relative;
}

.login-container-wrapper .login-container {
  height: 341px;
  width: 547px;
}

.login-container-wrapper .login-container .background-shadow {
  height: 313px;
  width: 513px;
  background-color: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: -28px;
  margin-left: 34px;
}

.login-container-wrapper .login-container .background-shadow.forgot {
  height: 253px;
  width: 513px;
  background-color: var(--white);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: -28px;
  margin-left: 34px;
}

.login-container-wrapper .login-container .content {
  height: 240px;
  width: 388px;
  margin: auto;
}

.login-container-wrapper .login-container .content.forgot {
  height: fit-content;
  width: 388px;
  margin: auto;
}

.login-container-wrapper .button-text-container {
  margin-top: 17px;
}

.login-container-wrapper .login-container .content .forgot-password {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--black);
}

.login-container-wrapper .login-container .content .button-signup {
  height: 41px;
  margin-top: 26px;
}

.login-container-wrapper .login-container .content .sign-up {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--purple);
}

.login-container-wrapper .reset-message {
  font-size: 22px;
}

@media screen and (max-width: 500px) {
  .login-container-wrapper {
    flex-direction: column;
    height: auto;
  }

  .login-container-wrapper .left-content {
    width: 100%;
  }

  .login-container-wrapper .right-content {
    width: 100%;
    margin-left: 0;
    background-color: var(--black);
    margin-top: -5px;
  }

  .login-container-wrapper .left-content .image {
    height: 100%;
  }

  .login-container-wrapper .login-container {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .login-container-wrapper .login-container .background-shadow {
    margin-left: 0;
    width: 100%;
  }

  .login-container-wrapper .login-container .content {
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 50px);
  }

  .login-container-wrapper .login-container .background-shadow.forgot {
    margin-left: 0;
    width: 100%;
  }

  .login-container-wrapper .login-container .content.forgot {
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 50px);
  }

  .login-container-wrapper .left-content .text {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 30px;
  }
}
