.about .about-section-1-wrapper {
  background-color: var(--bg-container);
  color: var(--white);
}

.about .image {
  object-position: bottom center;
}

.about .about-section-1-wrapper .about-section-1 {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  gap: 80px 40px;
  padding-top: 90px;
  padding-bottom: 190px;
}

.about .about-section-1-wrapper .about-section-1 .box {
  width: 100%;
  border: 2px solid var(--purple);
  padding: 33px;
  position: relative;
}

.about .about-section-1-wrapper .about-section-1 .box h2 {
  margin-top: -63px;
  margin-bottom: 36px;
}

.about .content-title {
  color: var(--primary);
}

@media screen and (max-width: 500px) {
  .about .about-section-1-wrapper .about-section-1 {
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
  }
}
