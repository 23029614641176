.header {
  z-index: 10;
}

.header .content-container {
  margin: auto;
  width: 1201px;
  height: 165px;
}

.header .top-navigation {
  gap: 35px;
  background-color: var(--purple);
  align-items: center;
  padding: 12px 120px 14px 120px;
}

.header .main-navigation {
  gap: 16px;
  background-color: var(--black);
  padding: 14px 120px;
  align-items: start;
  z-index: 10;
}

.header .main-navigation.transparent {
  background-color: transparent;
  padding: 14px 120px;
}

.header .login-wrapper {
  margin-top: 5px;
}

.button.header {
  min-width: 90px;
  text-align: center;
}

.header .logo-wrapper {
  z-index: 1;
}

.header .logo,
.mobile-navigation .logo {
  height: 87px;
  width: 235px;
  filter: drop-shadow(0px 0px 20px #000000);
}

.header .top-navigation .text-top-navigation {
  font-weight: 400;
  font-size: 18px;
  text-decoration: none;
  color: var(--white);
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: transparent;
}

.header .main-navigation .text-main-navigation {
  font-weight: 500;
  font-size: 19px;
  text-decoration: none;
  color: var(--white);
  margin-top: 10px;
  z-index: 11;
}

.header .main-navigation:hover .text-main-navigation.active {
  border-bottom: none;
}

.header .sign-up {
  color: var(--white);
  margin-top: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  z-index: 10;
}

.mobile-navigation {
  display: none;
  background-color: var(--black);
  align-items: center;
  padding: 24px;
}

.mobile-navigation.header-margin {
  margin-bottom: 120px;
}

.mobile-navigation .icon {
  font-size: 50px;
}

.menu .login-wrapper .sign-up {
  color: var(--white);
  margin-top: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  z-index: 10;
}

.menu {
  position: absolute;
  left: 0;
  height: calc(100vh - 108px);
  width: 100%;
  flex-direction: column;
  top: 0;
  margin-top: 133px;
  z-index: 11;
}

.menu .main {
  background-color: var(--black);
  display: flex;
  padding: 30px;
  height: calc((100vh - 133px) * 0.7);
  min-height: 350px;
}

.menu .secondary {
  background-color: var(--white);
  padding: 30px;
}

.menu .main .menu-item {
  color: var(--white);
}

.menu .secondary .menu-item {
  color: var(--black);
}

.menu .menu-item {
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  margin-bottom: 17px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: transparent;
  width: fit-content;
}

.menu .menu-item:hover,
.menu .menu-item.active {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--blue);
}

.menu .menu-item:last-child {
  margin-bottom: 0;
}

.menu {
  display: none;
}

.country-popup .close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.country-popup h2 {
  position: absolute;
  top: -20px;
  left: -2px;
}

.country-popup .content {
  margin: 100px 100px 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 50px;
}

.country-popup .content.program {
  margin: 90px 80px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px 50px;
  overflow: auto;
}

.country-popup .content.program .disclaimer {
  font-weight: 400;
  font-size: 15px;
  display: flex;
  line-height: 24px;
}

.country-popup .content .content-item {
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.country-popup .content .content-item img {
  margin-left: 9px;
}

.dialog-body .request-form {
  height: unset;
}

.navbar-line {
  background-color: var(--primary);
  position: absolute;
  transition: left 0.25s, width 0.25s, top 0.25s;
  z-index: 10;
}

@media screen and (max-width: 1700px) {
  .header .main-navigation {
    padding: 14px 20px;
  }
  .header .main-navigation.transparent {
    padding: 14px 20px;
  }

  .header .top-navigation {
    padding: 12px 40px 14px 40px;
  }
}

@media screen and (max-width: 1360px) {
  .header .main-navigation,
  .header .top-navigation {
    display: none;
  }

  .mobile-navigation {
    display: flex;
  }

  .menu {
    overflow: auto;
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .menu {
    top:-24px;
  }

  .menu .main {
    height: unset;
    min-height: unset;
  }

  .country-popup h2 {
    font-size: 26px;
  }

  .country-popup h2 {
    font-size: 24px;
  }

  .menu .menu-item {
    display: flex;
    align-items: baseline;
  }

  .menu-item.no-margin {
    margin-bottom: 0;
  }

  .country-popup .content.program.less-margin {
    margin: 90px 30px 45px;
  }

  .dialog-body .request-form {
    max-width: 100%;
    height: 550px;
  }
  .header .logo,
  .mobile-navigation .logo {
    height: 58px;
    width: 157px;
  }
}

@media screen and (max-height: 981px) {
  .dialog-body .request-form {
    height: 550px;
  }
}

@media screen and (max-height: 721px) {
  .dialog-body .request-form {
    height: 500px;
  }
}

@media screen and (max-height: 668px) {
  .dialog-body .request-form {
    height: 400px;
  }
}

@media screen and (max-height: 557px) {
  .dialog-body .request-form {
    height: 300px;
  }
}

@media screen and (max-height: 430px) {
  .dialog-body .request-form {
    height: 200px;
  }
}
