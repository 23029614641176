.cms-sidebar {
  border-right: 1px solid var(--black);
  padding: 0 20px;
}

.cms-sidebar .country {
  font-size: 20px;
}

.cms-sidebar .language {
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.cms-sidebar .language.selected,
.cms-sidebar .language:hover {
  border-bottom: 1px solid var(--primary);
}
