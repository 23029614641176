.faq {
  background-color: var(--black);
  display: flex;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 90px;
  flex-direction: column;
}

.faq h2 {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.faq .faq-items {
  margin-top: 40px;
  width: 80%;
  max-width: 700px;
}

.faq .faq-items .faq-item {
  color: var(--white);
  border-bottom: 1px solid var(--white);
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  padding-bottom: 3px;
}

.faq .faq-items .faq-item .faq-description {
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 10px;
  margin-left: 3px;
  margin-top: 10px;
  cursor: default;
}

.faq .faq-items .faq-item img {
  position: absolute;
  right: 10px;
  top: 7px;
}
