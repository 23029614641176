.introduction-wrapper {
  background-color: var(--black);
  padding-left: 30px;
  padding-right: 30px;
}

.introduction-wrapper .introduction {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 94px;
  padding-bottom: 76px;
  display: flex;
  min-height: calc(584px - 94px - 76px);
}

.introduction-wrapper .introduction .left-content {
  width: 50%;
}

.introduction-wrapper .introduction .left-content .content {
  max-width: 500px;
  margin-left: auto;
}

.introduction-wrapper .introduction .left-content .text {
  margin-top: 30px;
  color: var(--white);
  margin-bottom: 31px;
}

.introduction-wrapper .introduction .right-content {
  width: 50%;
  margin-left: 50px;
}

.introduction-wrapper .introduction .right-content .image-wrapper {
  background-color: var(--primary);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 572px;
  height: 572px;
  position: absolute;
  max-width: 100%;
}

.introduction-wrapper .introduction .right-content .image-wrapper .image {
  object-fit: contain;
}

.introduction-wrapper .introduction .left-content .cta-button {
  margin-left: auto;
}

@media screen and (max-width: 1250px) {
  .introduction-wrapper .introduction .right-content .image-wrapper {
    width: 432px;
    height: 432px;
  }

  .introduction-wrapper .introduction .right-content .image-wrapper .image {
    width: 80%;
    height: 80%;
  }

  .introduction-wrapper .introduction {
    padding-bottom: 20px;
    min-height: calc(584px - 94px - 146px);
  }
}

@media screen and (max-width: 1000px) {
  .introduction-wrapper .introduction .right-content .image-wrapper {
    width: 365px;
    height: 365px;
  }

  .introduction-wrapper .introduction .right-content .image-wrapper .image {
    width: 80%;
    height: 80%;
  }

  .introduction-wrapper .introduction {
    min-height: calc(584px - 94px - 220px);
  }
}

@media screen and (max-width: 850px) {
  .introduction-wrapper .introduction {
    flex-direction: column;
  }

  .introduction-wrapper .introduction .left-content {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    align-items: center;
  }

  .introduction-wrapper .introduction .left-content .content {
    margin-left: 0;
  }

  .introduction-wrapper .introduction .right-content {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    height: 232px;
  }

  .introduction-wrapper .introduction .right-content .image-wrapper {
    width: 232px;
    height: 232px;
  }

  .introduction-wrapper .introduction .right-content .image-wrapper .image {
    width: 80%;
    height: 80%;
  }

  .introduction-wrapper .introduction .left-content .cta-button {
    margin-left: auto;
  }
}
