.section4-wrapper {
  max-height: 490px;
  height: 725px;
  position: relative;
  width: 100%;
}

.section4-wrapper h2 {
  margin-top: -30px;
}

.section4-wrapper .image {
  margin-top: -21px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section4-wrapper .section4-footer {
  position: absolute;
  bottom: 60px;
  width: calc(100% - 60px);
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 500px) {
  .section4-wrapper {
    width: 100%;
    height: auto;
    margin-bottom: -21px;
    margin-top: -21px;
  }

  .section4-wrapper .button {
    margin-left: 0px;
    margin-right: 0px;
  }

  .section4-wrapper h2 {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
  }
}