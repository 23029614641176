.country-wrapper {
  color: var(--white);
}

.country-wrapper img {
  filter: invert(1);
}

.country-wrapper .black {
  filter: invert(0);
}

