.forces {
  margin-top: -1px;
}

.forces .forces-hero {
  margin-bottom: -1px;
  background-color: #1b1b1b;
  margin-top: -120px;
  height: calc(610px - 185px + 120px);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: relative;
}

.forces .forces-hero:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(0deg, #1b1b1b 0%, rgba(27, 27, 27, 0.4) 24.48%);
}

.forces .forces-hero .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.forces .forces-hero .content {
  position: absolute;
  font-weight: 400;
  font-size: 49px;
  text-align: center;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.forces .section .content.content1 {
  margin-bottom: 50px;
  min-height: 551px;
}

.forces .section .content.content2 {
  min-height: 383px;
}

.forces .section .content.content3 {
  min-height: 489px;
}

.forces .forces-hero .content .purple {
  color: var(--purple);
  font-size: 52px;
  height: fit-content;
}

.forces .forces-hero .content .primary {
  color: var(--primary);
}

.forces .forces-introduction-wrapper {
  background: var(--bg);
}

.forces .forces-introduction-wrapper .introduction {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 94px;
  padding-bottom: 76px;
  display: flex;
}

.forces .forces-introduction-wrapper .introduction .left-content {
  width: 50%;
  margin-left: 25px;
}

.forces .forces-introduction-wrapper .introduction .left-content .text {
  margin-top: 23px;
  color: var(--white);
  margin-bottom: 31px;
}

.forces .forces-introduction-wrapper .introduction .right-content {
  width: 50%;
  margin-left: 50px;
  margin-top: -200px;
}

.forces
  .forces-introduction-wrapper
  .introduction
  .right-content
  .image-wrapper {
  background-color: var(--primary);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 45vw;
  position: absolute;
  max-width: 572px;
  max-height: 572px;
}

.forces
  .forces-introduction-wrapper
  .introduction
  .right-content
  .image-wrapper
  .image {
  object-fit: contain;
}

.forces .forces-introduction-wrapper .introduction .left-content .cta-button {
  margin-left: auto;
}

.forces .section-title {
  padding-top: 120px;
  display: flex;
  justify-content: center;
}

.forces .section {
  max-width: 1000px;
  padding-left: calc(max((100vw - 1000px), 30px) / 2);
  padding-right: calc(max((100vw - 1000px), 30px) / 2);
  display: flex;
  justify-content: space-between;
  padding-top: 180px;
  padding-bottom: 180px;
}

.forces .section.black {
  background: var(--bg);
  color: var(--white);
}

.forces .section.gray {
  background: var(--gray);
}

.forces .section .content {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  width: 45%;
}

.forces .section .square,
.forces .section .circle {
  background: var(--purple);
  width: min(50vw, 360px);
  height: min(50vw, 360px);
  z-index: 10;
}

.forces .section .square {
  transform: translateY(25%) rotate(45deg);
}

.forces .section .circle {
  border-radius: 100%;
}

.forces .section .square .image,
.forces .section .circle .image {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  width: calc(min(50vw, 360px) - 60px);
  height: calc(min(50vw, 360px) - 60px);
  object-fit: cover;
  margin: 30px;
}

.forces .section .circle .image {
  border-radius: 100%;
}

.forces .line {
  background: var(--purple);
  height: 30px;
  transform: rotate(45deg);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.forces .line.line1 {
  margin-top: -190px;
}

.forces .line.line2 {
  margin-bottom: -145px;
}

.forces .line.line3 {
  margin-top: -95px;
}

.forces .line.rotate {
  transform: rotate(-45deg);
}

.forces .section .forces-title {
  font-weight: 500;
  font-size: 32px;
  margin-bottom: 25px;
}

.forces .section .text {
  font-weight: 300;
  font-size: 16px;
  opacity: 0.8;
}

.forces .grid-box-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.forces .grid-box-wrapper .image {
  width: 100%;
  height: calc((min(100vw, 1100px)) / 3);
  object-fit: cover;
}

.forces .grid-box-wrapper .grid-box {
  background: var(--bg);
  box-shadow: inset 0 0 20px #000000;
  position: relative;
  height: calc((min(100vw, 1100px)) / 3);
  width: 100%;
}

.forces .grid-box-wrapper .grid-box .grid-content {
  margin: 30px;
  position: relative;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  color: var(--white);
  display: flex;
  flex-direction: column;
}

.forces .grid-box-wrapper .grid-box .grid-content .grid-title {
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
  margin-left: 20px;
}

.forces .grid-box-wrapper .grid-box .grid-content .forces-excerpt {
  font-weight: 300;
  font-size: 15px;
  margin: 20px 25px 13px 20px;
  overflow: hidden;
  word-break: break-word;
}

.forces .grid-box-wrapper .grid-box .grid-content .button-wrapper {
  margin-top: auto;
  margin-right: 25px;
  margin-bottom: 42px;
}

.forces .grid-box-wrapper .grid-box .grid-content {
  border: 1px solid var(--primary);
}

.forces .grid-box-wrapper .grid-box .grid-content .figure {
  background: var(--primary);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  border-radius: 100%;
  z-index: 10;
}

.forces .wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.forces .bg {
  background: var(--bg);
  height: 300px;
  margin-bottom: -300px;
}

.forces .contact-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  margin-bottom: 200px;
}

.forces .contact-wrapper .contact-form {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  background: var(--white);
}

.forces .contact-wrapper .contact-form .input-wrapper {
  display: flex;
  gap: 66px;
}

.forces .contact-wrapper .contact-form .sentence {
  width: 100%;
  display: flex;
  align-items: baseline;
  font-weight: 400;
  font-size: 22px;
}

.forces .contact-wrapper .contact-form .sentence .text-1 {
  width: 100px;
}

.forces .contact-wrapper .contact-form .sentence .text-2 {
  width: 200px;
}

.forces .contact-wrapper .contact-form .form__group {
  width: 100%;
}

.forces .contact-wrapper h2,
.forces .wrapper h2 {
  margin-left: -30px;
  margin-bottom: -15px;
}

@media screen and (max-width: 1100px) {
  .forces .contact-wrapper h2,
  .forces .wrapper h2 {
    margin-left: 5px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1000px) {
  .forces .forces-introduction-wrapper .introduction {
    flex-direction: column;
  }

  .forces .forces-hero {
    height: calc(100vh - 350px);
  }

  .forces .forces-hero .content {
    font-size: 20px;
    justify-content: center;
  }

  .forces .forces-introduction-wrapper .introduction .left-content {
    width: calc(100% - 60px);
    padding: 30px;
    margin-left: 0;
  }

  .forces .forces-introduction-wrapper .introduction .right-content {
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    height: 232px;
  }

  .forces
    .forces-introduction-wrapper
    .introduction
    .right-content
    .image-wrapper {
    width: 232px;
    height: 232px;
  }

  .forces
    .forces-introduction-wrapper
    .introduction
    .right-content
    .image-wrapper
    .image {
    width: 80%;
    height: 80%;
  }

  .forces .forces-introduction-wrapper .introduction .left-content .cta-button {
    margin-left: auto;
  }

  .forces .grid-box-wrapper .grid-box {
    height: fit-content;
  }

  .forces .contact-wrapper .contact-form .input-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .forces .contact-wrapper,
  .forces .wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }

  .forces .contact-wrapper h2,
  .forces .wrapper h2 {
    margin-left: 0;
  }

  .forces .forces-hero .content {
    font-size: 35px;
    width: 90%;
  }

  .forces .forces-hero .content .purple {
    font-size: 35px;
  }
}

@media screen and (max-width: 965px) {
  .forces .section {
    flex-direction: column-reverse;
    padding-top: 30px;
  }

  .forces .section.black,
  .forces .section.gray {
    padding-top: 180px;
  }

  .forces .section .content {
    width: 100%;
  }

  .forces .section .square .image,
  .forces .section .circle .image {
    margin: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }

  .forces .section .circle {
    margin-left: auto;
  }

  .forces .line {
    height: 15px;
  }

  .forces .line.line1 {
    margin-top: -54px;
  }

  .forces .line.line3 {
    margin-top: -62px;
  }

  .forces .section .square {
    transform: rotate(45deg);
    margin-bottom: calc(-50vw + 26px);
    margin-top: 50px;
    width: 40vw;
    height: 40vw;
    margin-left: 20px;
  }

  .forces .section .circle {
    margin-top: -20vw;
  }

  .forces .line.rotate {
    display: none;
  }

  .forces .contact-wrapper .contact-form .sentence {
    flex-direction: column;
  }

  .forces .contact-wrapper .contact-form .sentence .text-1,
  .forces .contact-wrapper .contact-form .sentence .text-2 {
    margin-bottom: -20px;
    margin-top: 20px;
    font-size: 16px;
  }

  .forces .forces-introduction-wrapper .introduction .left-content .text {
    font-size: 16px;
  }

  .forces .forces-hero .content {
    font-size: 30px;
    width: 90%;
  }

  .forces .forces-hero .content .purple {
    font-size: 30px;
  }

  .forces .contact-wrapper .contact-form {
    padding: 50px 25px;
  }

  .forces .section .forces-title {
    font-size: 22px;
  }

  .forces .grid-box-wrapper .grid-box .grid-content .grid-title {
    font-size: 22px;
  }

  .forces .grid-box-wrapper .grid-box .grid-content .forces-excerpt {
    font-size: 12px;
  }
}

@media screen and (max-width: 500px) {
  .forces .line.line1 {
    margin-top: -113px;
  }

  .forces .line.line3 {
    margin-top: -105px;
  }

  .forces .grid-box-wrapper .grid-box .grid-content .forces-excerpt {
    margin-top: 0;
  }

  .forces .section .circle {
    margin-top: -50vw;
  }

  .forces .section .content.content3,
  .forces .section .content.content2,
  .forces .section .content.content1 {
    min-height: 0px;
  }

  .forces .section.funding {
    padding-bottom: 60px;
  }
}
