.information .container {
  padding-left: 30px;
  padding-right: 30px;
  max-width: min(1200px, calc(100vw - 60px));
}

.information .topics-wrapper {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background: var(--white);
  padding: 30px 120px;
  display: flex;
  gap: 13px 30px;
  flex-wrap: wrap;
}

.information h2 {
  margin-top: 50px;
  margin-bottom: -15px;
  margin-left: -15px;
}

.information .bg {
  background: var(--bg-container);
  height: 150px;
  margin-top: -80px;
}

.information .topics-wrapper .topic-item {
  padding: 8px 12px;
  border: 2px solid var(--purple);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: var(--black);
  transition: all 0.25s;
}

.information .topics-wrapper .topic-item:hover,
.information .topics-wrapper .topic-item.active {
  background: var(--purple);
  color: white;
}

@media screen and (max-width: 500px) {
  .information .container {
    padding-left: 5px;
    padding-right: 5px;
    max-width: min(1200px, calc(100vw - 10px));
  }

  .information .bg {
    background: var(--bg-container);
    height: 100px;
    margin-top: -80px;
  }

  .information .topics-wrapper {
    padding: 10px;
    max-width: calc(100% - 60px);
    margin-left: 20px;
    margin-right: 20px;
    gap: 12px;
  }

  .information h2 {
    margin-top: 50px;
    margin-bottom: 0;
    margin-left: 20px;
  }

  .information .topics-wrapper .topic-item {
    font-size: 14px;
  }
}
