.submenu-wrapper {
  position: relative;
}

.submenu-wrapper .submenu {
  height: 0;
  overflow: hidden;
  z-index: 1;
  position: absolute;
}

.submenu-wrapper .submenu.mobile {
  top: 26px;
}

.submenu-wrapper .submenu .submenu-content {
  background-color: var(--white);
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: column;
  width: max-content;
  z-index: 10;
  gap: 5px;
}

.submenu-wrapper .submenu .submenu-content .submenu-item {
  font-weight: 400;
  font-size: 17px;
  text-decoration: none;
  color: var(--black);
  border-bottom: 2px solid transparent;
  z-index: 1;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  transition: all 0.15s;
  width: fit-content;
  padding-right: 10px;
}

.submenu-wrapper .submenu .submenu-item:hover,
.submenu-wrapper .submenu .submenu-item.active {
  border-bottom: 2px solid var(--primary);
}
