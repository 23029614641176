.footer-container {
  max-width: 100%;
}

.footer-container-top {
  background: linear-gradient(103.51deg, var(--purple) -0.47%, #544ec4 59.52%);
  display: flex;
  max-width: 100%;
  padding: 30px;
}

.footer-container-bottom {
  height: 50px;
  background: var(--black);
  color: var(--white);
  max-width: 100%;
}

.text-footer-container-bottom {
  letter-spacing: 0.025em;
  color: var(--white);
  max-width: 100%;
}

.footer-container-content {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.footer-container-content .footer-inner-container {
  display: flex;
  gap: 133px;
}

.footer-container-connect {
  margin-top: 60px;
}

.footer-container-information {
  margin-top: auto;
  width: 50%;
}

.footer-container-information .text-information {
  color: rgba(255, 255, 255, 0.8);
}

.footer-container-navigation {
  min-height: 88px;
  margin-bottom: 30px;
  margin-top: 60px;
  gap: 33px;
  flex-wrap: wrap;
}

.checkbox-button-container {
  padding-top: 17px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.footer-container .logo {
  width: 329px;
  height: 120px;
  margin-bottom: 40px;
}

.text-information {
  font-weight: 500;
  font-size: 24px;
  line-height: 94.8%;
  color: var(--white);
  text-decoration: none;
}

.sub-title {
  color: var(--blue);
  font-weight: 500;
  font-size: 32px;
  line-height: 94.8%;
}

.social-logo-box {
  margin-bottom: 34px;
  gap: 16px;
}

.social-logo {
  width: 20px;
  height: 20px;
  color: var(--blue);
}

.gap {
  gap: 133px;
}

.text-checkbox {
  font-weight: 400;
  font-size: 18px;
  line-height: 125.8%;
}

.navigation-column {
  height: 88px;
}

.navigation-column.gap {
  gap: 8px;
}

.text-navigation {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  margin-bottom: 7px;
}

.text-navigation.bold {
  color: var(--white);
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
  margin-bottom: 5px;
}

.footer-container-navigation-wrapper {
  display: flex;
  gap: 30px;
}

.footer-container .copyright {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 850px) {
  .footer-container-content .footer-inner-container {
    flex-direction: column;
    gap: 30px;
  }

  .footer-container-information {
    width: 100%;
  }

  .checkbox-button-container {
    flex-direction: column;
  }

  .checkbox-button-container .button {
    margin-top: 10px;
    margin-left: 0;
  }

  .footer-container-navigation-wrapper {
    flex-direction: column;
    gap: 0px;
  }

  .footer-container-navigation {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  .footer-container .logo {
    width: 196px;
    height: 72px;
    margin-left: auto;
    margin-right: auto;
  }

  .sub-title {
    font-size: 22px;
  }

  .footer-container-information .text-information {
    font-size: 18px;
  }
}
