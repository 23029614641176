.hero {
  margin-top: -120px;
  height: calc(863px - 185px + 120px);
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

.hero video {
  width: 100%;
  height: calc(863px - 185px + 120px);
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.hero .content-wrapper {
  height: 100%;
  margin-top: 120px;
  display: flex;
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  z-index: 10;
}

.hero .content-wrapper .content {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.hero .content-wrapper .content .cta-button {
  margin-left: auto;
}

.hero .content-wrapper .content .content-text {
  font-weight: 400;
  font-size: 17px;
  line-height: 125.8%;
  margin-top: 17px;
  margin-bottom: 35px;
  margin-left: 10px;
  text-align: justify;
}

.hero .bullets {
  width: 100%;
  display: flex;
  max-width: 1050px;
  z-index: 10;
  padding-top: 10px;
  margin: -10px auto 65px;
  mask-image: linear-gradient(to right, transparent 0, black 10%, black 90%, transparent 100%);
}

.hero .bullets .bullet {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.hero .bullets .bullet .line {
  height: 2px;
  position: relative;
  background-color: white;
}

.hero .bullets .bullet .line:before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
  background-color: #3eedd4;
  position: absolute;
}

.hero .bullets .bullet .line.filled:before {
  width: 100%;
}

.hero .bullets .bullet .line.active:before {
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.hero .bullets .bullet .line:after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 100%;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero .bullets .bullet .line.filled:after {
  background-color: var(--primary);
}

.hero .bullets .bullet .line.active:after {
  background-color: var(--primary);
  transition-delay: 0.235s;
  transition-property: all;
}

.hero .bullets .bullet .text {
  text-align: center;
  color: var(--white);
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
}

@media screen and (max-width: 850px) {
  .hero {
    padding: 30px;
    height: calc(100vh - 178px);
    margin-top: -120px;
  }

  .hero .bullets {
    flex-direction: column;
    height: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    margin-right: -10px;
    padding-right: 10px;
    padding-top: 0;
    z-index: 10;
    mask-image: linear-gradient(to bottom, transparent 0, black 10%, black 90%, transparent 100%);
  }

  .hero .bullets.absolute {
    position: unset;
  }

  .hero .bullets .bullet {
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
  }

  .hero .bullets .bullet .text {
    margin-top: 0;
    margin-right: 15px;
    font-size: 14px;
  }

  .hero .bullets .bullet .line {
    width: 2px;
    height: 100%;
  }

  .hero .content-wrapper {
    height: auto;
    margin-top: 0;
  }

  .hero .content-wrapper .content {
    margin-bottom: 0;
    margin-top: 30px;
    z-index: 10;
  }

  .hero .content-wrapper.absolute {
    position: unset;
  }

  .hero .content-wrapper .content .cta-button {
    margin-left: 0;
  }

  .hero video {
    height: calc(100vh - 140px);
  }

  .hero .content-wrapper .content .content-text {
    font-size: 16px;
  }
}
