.section7-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.section7-wrapper .section7 {
  display: flex;
  gap: 50px;
  max-width: 1000px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

.section7-wrapper .section7-bg {
  background-color: var(--black);
  height: 225px;
  width: 100%;
}

.section7-wrapper .title-wrapper {
  background-color: var(--black);
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
}

.section7-wrapper .section7 .frame {
  margin-top: -225px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: calc((min(100vw, 1200px)) / 2);
}

.section7-wrapper .section7 .frame .image {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.section7-wrapper .section7 .frame .content {
  padding: 25px 19px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section7-wrapper .section7 .frame .content .frame-title {
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
}

.section7-wrapper .section7 .frame .content .frame-content {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 35px;
}

@media screen and (max-width: 1080px) {
  .section7-wrapper .section7 .frame {
    width: calc(100vw/2 - 60px);
  }
}

@media screen and (max-width: 950px) {
  .section7-wrapper {
    margin-top: 30px;
  }

  .section7-wrapper .section7 {
    flex-direction: column;
    padding: 30px;
  }

  .section7-wrapper .section7 .frame {
    margin-top: 0;
    width: calc(100vw - 60px);
  }

  .section7-wrapper .section7-bg {
    display: none;
  }

  .section7-wrapper .title-wrapper {
    background-color: var(--white);
  }

  .section7-wrapper .title-wrapper h2 {
    margin-right: 15px;
    margin-left: 15px;
  }

  .section7-wrapper .section7 .frame .image {
    height: 221px;
  }

}
