.grid .grid-wrapper {
  background: var(--bg-container);
  padding-bottom: 200px;
}

.grid .grid-wrapper .button {
  background-color: rgb(20 20 20 / 10%);
}

.grid .grid-wrapper .button:hover {
  background-color: var(--primary);
}

.grid .grid-wrapper .button.purple:hover {
  background-color: var(--purple);
}

.grid .grid-grid {
  width: 100%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}

.grid .grid-grid.full {
  max-width: 1000px;
}

.grid .grid-grid .grid-box {
  background: var(--bg);
  box-shadow: inset 0 0 20px #000000;
  position: relative;
  height: calc((min(100vw, 1000px)) / 3);
}

.grid .grid-grid.full .grid-box {
  height: calc((min(100vw, 1000px)) / 3);
}

.grid .grid-grid .grid-box.w3-3 {
  width: min(100vw, 1099px);
}

.grid .grid-grid.full .grid-box.w3-3 {
  width: min(100vw, 1000px);
}

.grid .grid-grid .grid-box.w1-3 {
  width: calc(min(100vw, 1000px) / 3);
}

.grid .grid-grid.full .grid-box.w1-3 {
  width: calc(min(100vw, 1000px) / 3);
}

.grid .grid-grid .grid-box.w2-3 {
  width: calc(min(100vw, 1000px) * 2 / 3);
}

.grid .grid-grid.full .grid-box.w2-3 {
  width: calc(min(100vw, 1000px) * 2 / 3);
}

.grid .grid-grid .grid-box .grid-content {
  margin: 30px;
  position: relative;
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  color: var(--white);
  display: flex;
  flex-direction: column;
}

.grid .grid-grid .grid-box .grid-content .topic {
  margin-left: auto;
  margin-right: 25px;
  margin-top: 25px;
  font-weight: 400;
  font-size: 17px;
}

.grid .grid-grid .grid-box .grid-content .grid-title {
  font-weight: 500;
  font-size: 24px;
  margin-left: 20px;
}

.grid .grid-grid .grid-box .grid-content .excerpt {
  font-weight: 300;
  font-size: 15px;
  margin: 20px 25px 20px 20px;
}

.excerpt {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}

.grid .grid-grid .grid-box .grid-content .button-wrapper {
  margin-top: auto;
  margin-right: 25px;
  margin-bottom: 25px;
}

.grid .grid-grid .grid-box.w2-3 .grid-content {
  border: 1px solid var(--purple);
}

.grid .grid-grid .grid-box .grid-content .image-wrapper {
  display: none;
}

.grid .grid-grid .grid-box .grid-content.asset .image-wrapper {
  display: block;
}

.grid .grid-grid .grid-box .grid-content .content-wrapper {
  width: 100%;
}

.grid .grid-grid .grid-box.w2-3 .grid-content.asset,
.grid .grid-grid .grid-box.w3-3 .grid-content.asset {
  flex-direction: row;
}

.grid .grid-grid .grid-box.w2-3 .grid-content.asset .image-wrapper,
.grid .grid-grid .grid-box.w3-3 .grid-content.asset .image-wrapper {
  width: 50%;
}

.grid .grid-grid .grid-box.w1-3 .grid-content.asset .image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.grid .grid-grid .grid-box.w2-3 .grid-content.asset .content-wrapper,
.grid .grid-grid .grid-box.w3-3 .grid-content.asset .content-wrapper {
  width: 50%;
}

.grid .grid-grid .grid-box.w2-3 .grid-content.asset .image-wrapper .image,
.grid .grid-grid .grid-box.w3-3 .grid-content.asset .image-wrapper .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid .grid-grid .grid-box.w1-3 .grid-content.asset .image-wrapper .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid .grid-grid .grid-box.w1-3 .grid-content,
.grid .grid-grid .grid-box.w3-3 .grid-content {
  border: 1px solid var(--primary);
}

.grid .grid-grid .grid-box.w2-3 .grid-content .figure {
  background: var(--purple);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  transform: rotate(45deg);
  z-index: 10;
}

.grid .grid-grid .grid-box.w1-3 .grid-content .figure,
.grid .grid-grid .grid-box.w3-3 .grid-content .figure {
  background: var(--primary);
  width: 17px;
  height: 17px;
  position: absolute;
  top: -9px;
  left: calc(50% - 8.7px);
  border-radius: 100%;
  z-index: 10;
}

.grid .grid-grid .grid-box .grid-content .content-wrapper {
  height: 100%;
  z-index: 10;
}

@media screen and (max-width: 950px) {
  .excerpt {
    -webkit-line-clamp: 3;
  }

  .grid .grid-grid .grid-box .grid-content .button-wrapper {
    margin-right: 9px;
    margin-bottom: 15px;
  }

  .grid .grid-grid .grid-box .grid-content .button {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .grid .grid-grid .grid-box,
  .grid .grid-grid.full .grid-box {
    height: calc((min(100vw, 1000px)) / 2);
  }

  .grid .grid-grid .grid-box.w1-3,
  .grid .grid-grid .grid-box.w2-3,
  .grid .grid-grid.full .grid-box.w1-3,
  .grid .grid-grid.full .grid-box.w2-3 {
    width: calc((min(100vw, 1000px)) / 2);
  }

  .section-3-wrapper .grid .grid-grid .grid-box,
  .section-3-wrapper .grid .grid-grid.full .grid-box {
    height: calc((min(100vw, 1000px) - 30px) / 2);
  }

  .section-3-wrapper .grid .grid-grid .grid-box.w1-3,
  .section-3-wrapper .grid .grid-grid .grid-box.w2-3,
  .section-3-wrapper .grid .grid-grid.full .grid-box.w1-3,
  .section-3-wrapper .grid .grid-grid.full .grid-box.w2-3 {
    width: calc((min(100vw, 1000px) - 30px) / 2);
  }


  .grid .grid-grid .grid-box .grid-content .topic {
    margin-right: 15px;
    margin-top: 9px;
    font-size: 14px;
  }

  .grid .grid-grid .grid-box .grid-content .excerpt {
    margin-left: 15px;
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 13px;
    -webkit-line-clamp: 6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .grid .grid-grid .grid-box.w2-3 .grid-content.asset .image-wrapper,
  .grid .grid-grid .grid-box.w3-3 .grid-content.asset .image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .grid .grid-grid .grid-box.w2-3 .grid-content.asset .content-wrapper,
  .grid .grid-grid .grid-box.w3-3 .grid-content.asset .content-wrapper {
    width: 100%;
  }

  .grid .grid-grid .grid-box.w1-3 .grid-content.asset .image-wrapper,
  .grid .grid-grid .grid-box.w2-3 .grid-content.asset .image-wrapper,
  .grid .grid-grid .grid-box.w3-3 .grid-content.asset .image-wrapper {
    opacity: 50%;
  }

  .grid .grid-grid .grid-box .grid-content .button {
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {

  .grid .grid-grid .grid-box .grid-content {
    margin: 20px auto;
    width: calc(100% - 20px);
    height: calc(100% - 50px);
    font-size: 14px;
  }


  .grid .grid-grid .grid-box .grid-content .topic {
    margin-right: 15px;
    margin-top: 9px;
    font-size: 14px;
  }

  .grid .grid-grid .grid-box .grid-content .grid-title {
    margin-left: 15px;
    font-size: 14px;
  }

  .grid .grid-grid .grid-box .grid-content .excerpt {
    margin-left: 15px;
    margin-top: 3px;
    margin-bottom: 0;
    font-size: 11px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .grid .grid-grid .grid-box .grid-content .button-wrapper {
    margin-left: auto;
    margin-right: 4px;
    margin-bottom: 6px;
  }

  .grid .grid-grid .grid-box .grid-content .button {
    font-size: 10px;
  }
}
